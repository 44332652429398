<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">医院基期数据填报</h2>
        <div class="hosrow">
          <div>基期年份</div>
          <div>
            <el-button type="primary" @click="depaddYear"
              >添加基期年份</el-button
            >
          </div>
        </div>
        <el-table
          :data="tableData"
          style="width: 80%; margin: 0 auto"
          border
          center
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column
            prop="year"
            label="基期年份"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="updateDate" label="更新时间" align="center">
          </el-table-column>
          <el-table-column label="填报状态" align="center">
            <template slot-scope="scope">
              <div class="activeStatus" v-if="scope.row.status == 0">√</div>
              <div class="noStatus" v-else>√</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template style="display: flex" slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="listJixiao(scope.row)"
                ><i class="el-icon-notebook-2"></i
              ></el-button>
              <el-button
                size="mini"
                type="primary"
                @click="editJixiao(scope.row)"
                ><i class="el-icon-document-copy"></i
              ></el-button>
              <el-button
                size="mini"
                type="primary"
                @click="updateHospitalSalary(scope.row)"
                ><i class="el-icon-menu"></i
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <!-- 点击添加年份弹出框 -->
    <el-dialog
      title="添加基期年份"
      :visible.sync="AdddialogVisible"
      width="25%"
      :before-close="AdddialogVisibleClose"
    >
      <el-form
        :model="AddDateModel"
        ref="AddDateRef"
        :rules="AddDateRules"
        :inline="true"
      >
        <el-form-item label="年份：" prop="year">
          <el-date-picker
            v-model="AddDateModel.year"
            type="year"
            value-format="yyyy"
            placeholder="选择年份"
            @change="changeOptios"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" class="addprimary" @click="addYear"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院基础数据的机构、人员、床位及工作量 -->
    <el-dialog
      title="机构、人员、床位及工作量"
      :visible.sync="personneldialogVisible"
      width="50%"
    >
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="160px"
        :model="saveHospitalBaseOneModel"
        ref="saveHospitalBaseOneRef"
        :rules="saveHospitalBaseOneRules"
      >
        <el-form-item label="机构数：" prop="one" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.one"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="编制人数："
          prop="organizationPeople"
          class="hosform"
        >
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseOneModel.organizationPeople"
            ></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="在编在职人数：" prop="jobPeople" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.jobPeople"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="在职职工人数："
          prop="workersPeople"
          class="hosform"
        >
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseOneModel.workersPeople"
            ></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="其中：总会计师："
          prop="accountant"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.accountant"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="卫技人员：" prop="health" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.health"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：医师:" prop="doctor" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.doctor"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="注册护士：" prop="nurse" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.nurse"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="离休人数：" prop="leave" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.leave"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="退休人数：" prop="retiree" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.retiree"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="临时工人数：" prop="temp" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.temp"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="其中：卫技人员："
          prop="tempHealth"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.tempHealth"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制床位：" prop="authBeds" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.authBeds"></el-input>
            <div class="hosdanwei">(张)</div>
          </div>
        </el-form-item>
        <el-form-item label="平均开放床位：" prop="avgBeds" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.avgBeds"></el-input>
            <div class="hosdanwei">(张)</div>
          </div>
        </el-form-item>
        <el-form-item label="期末实际开放床位：" prop="endBeds" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.endBeds"></el-input>
            <div class="hosdanwei">(张)</div>
          </div>
        </el-form-item>
        <el-form-item label="诊疗人次数：" prop="patients" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.patients"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="其中：门急诊人次数"
          prop="patientsOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.patientsOne"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="实际开放总床日数：" prop="dayOne" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.dayOne"></el-input>
            <div class="hosdanwei">(床日)</div>
          </div>
        </el-form-item>
        <el-form-item label="实际占用总床日数：" prop="dayTwo" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.dayTwo"></el-input>
            <div class="hosdanwei">(床日)</div>
          </div>
        </el-form-item>
        <el-form-item label="出院者占用总床日：" prop="dayFour" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.dayFour"></el-input>
            <div class="hosdanwei">(床日)</div>
          </div>
        </el-form-item>
        <el-form-item label="出院人数：" prop="discharge" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseOneModel.discharge"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="xxxx："
          prop=""
          class="hosform"
          style="visibility: hidden"
        >
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveHospitalBaseOne"
            >提交数据</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 点击操作绩效分配结构 -->
    <el-dialog
      title="绩效分配结构"
      :visible.sync="EditdialogVisible"
      width="45%"
    >
      <el-form
        style="width: 100%"
        :model="Performance"
        ref="PerformanceRef"
        :rules="PerformanceRules"
        :inline="true"
        label-width="120px"
      >
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="editdialogKeshi"
            >提交数据</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 点击操作的第三个按钮 -->
    <el-dialog
      class="updateTan"
      title="2015年医务人员平均薪资"
      :visible.sync="UpdatedialogVisible"
      width="25%"
      :before-close="UpdatedialogVisibleClose"
    >
      <h6 class="twotex">时薪计算：（月平均薪资÷21.75天）÷8=时薪</h6>
      <el-form
        :model="UpdateDateModel"
        ref="UpdateDateRef"
        :rules="UpdateDateRules"
        :inline="true"
      >
        <el-form-item label="医生：" prop="doctorSalary">
          <el-input v-model="UpdateDateModel.doctorSalary"></el-input>
        </el-form-item>
        <el-form-item label="护士：" prop="nursingSalary">
          <el-input v-model="UpdateDateModel.nursingSalary"></el-input>
        </el-form-item>
        <el-form-item label="技师：" prop="technicianSalary">
          <el-input v-model="UpdateDateModel.technicianSalary"></el-input>
        </el-form-item>
        <el-form-item label="工勤：" prop="workSalary">
          <el-input v-model="UpdateDateModel.workSalary"></el-input>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="addUpdateDate">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 点击操作的第一个按钮弹出框 -->
    <el-dialog
      title="医院基础数据"
      :visible.sync="HospitaldatadialogVisible"
      width="50%"
      :before-close="HospitaldatadialogVisibleClose"
    >
      <div class="hosrowTable" style="margin-top: 30px">
        <div class="hostextw">{{ hospitalTitle }}年医院基础数据</div>
      </div>
      <el-table
        :data="HospitaltableData"
        style="width: 95%; margin: 0 auto"
        border
        center
      >
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="数据名称"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="updateDate" label="更新时间" align="center">
        </el-table-column>
        <el-table-column label="填报状态" align="center">
          <template slot-scope="scope">
            <div class="activeStatus" v-if="scope.row.status == 0">√</div>
            <div class="noStatus" v-else>√</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template style="display: flex" slot-scope="scope">
            <el-button size="mini" type="primary" @click="Hospital(scope.row)"
              ><i class="el-icon-edit"></i
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="hosrowTable" style="margin-top: 30px">
        <div class="hostextw">{{ hospitalTitle }}年医院主要指标</div>
      </div>
      <el-table
        :data="IndicatorstableData"
        style="width: 95%; margin: 0 auto"
        border
        center
      >
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="数据名称"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="updateDate" label="更新时间" align="center">
        </el-table-column>
        <el-table-column label="填报状态" align="center">
          <template slot-scope="scope">
            <div class="activeStatus" v-if="scope.row.deleteFlag == 0">√</div>
            <div class="noStatus" v-else>√</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template style="display: flex" slot-scope="scope">
            <el-button size="mini" type="primary" @click="indicators(scope.row)"
              ><i class="el-icon-edit"></i
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 医院基础数据的固定资产 -->
    <el-dialog
      title="固定资产"
      :visible.sync="fixassetsdialogVisible"
      width="60%"
    >
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="180px"
        :model="saveHospitalBaseTwoModel"
        ref="saveHospitalBaseTwoRef"
        :rules="saveHospitalBaseTwoRules"
      >
        <el-form-item
          label="年初固定资产原值："
          prop="fixedOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.fixedOne"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="本期增加固定资产原值："
          prop="fixedTwo"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.fixedTwo"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="本期减少固定资产原值："
          prop="fixedThree"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.fixedThree"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="期末固定资产原值：" prop="endOne" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.endOne"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：专用设备：" prop="endTwo" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.endTwo"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="房屋及建筑物：" prop="endThree" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.endThree"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：业务用房：" prop="endFour" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.endFour"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="年初固定资产净值："
          prop="beginFixed"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.beginFixed"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="期末固定资产净值：" prop="netOne" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.netOne"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：专用设备：" prop="netTwo" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.netTwo"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="房屋及建筑物：" prop="netThree" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.netThree"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：业务用房：" prop="netFour" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.netFour"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="非财政资本性支出：" prop="expend" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseTwoModel.expend"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="xxxx："
          prop=""
          class="hosform"
          style="visibility: hidden"
        >
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveHospitalBaseTwo"
            >提交数据</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院基础数据的收入 -->
    <el-dialog title="收入" :visible.sync="incomedialogVisible" width="60%">
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="260px"
        :model="saveHospitalBaseThreeModel"
        ref="saveHospitalBaseThreeRef"
        :rules="saveHospitalBaseThreeRules"
      >
        <el-form-item label="医疗收入总额：" prop="total" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseThreeModel.total"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="其中:来源于城镇职工医疗保险基金："
          prop="totalOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseThreeModel.totalOne"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="来源于城镇居民医疗保险基金："
          prop="totalTwo"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseThreeModel.totalTwo"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="来源于新型农村合作医疗基金："
          prop="totalThree"
          class="hosform"
        >
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseThreeModel.totalThree"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="来源于城乡一体化医疗保险基金："
          prop="totalFour"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseThreeModel.totalFour"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="来源于其他医疗保险基金："
          prop="totalFive"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseThreeModel.totalFive"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="财政项目补助收入总额："
          prop="finance"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseThreeModel.finance"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="其中：基本建设："
          prop="financeOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseThreeModel.financeOne"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="设备购置：" prop="financeTwo" class="hosform">
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseThreeModel.financeTwo"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="公共服务任务："
          prop="financeThree"
          class="hosform"
        >
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseThreeModel.financeThree"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="重点学科发展：" prop="financeFour" class="hosform">
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseThreeModel.financeFour"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="取消药品加成专项补助："
          prop="financeFive"
          class="hosform"
        >
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseThreeModel.financeFive"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其他：" prop="financeSix" class="hosform">
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseThreeModel.financeSix"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="xxxx："
          prop=""
          class="hosform"
          style="visibility: hidden"
        >
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveHospitalBaseThree"
            >提交数据</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院基础数据的支出 -->
    <el-dialog
      title="支出"
      :visible.sync="expendituredialogVisible"
      width="60%"
    >
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="190px"
        :model="saveHospitalBaseFourModel"
        ref="saveHospitalBaseFourRef"
        :rules="saveHospitalBaseFourRules"
      >
        <el-form-item label="临时工工资：" prop="temp" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseFourModel.temp"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="药品平均占用额：" prop="avg" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseFourModel.avg"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="设备购置总额：" prop="total" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseFourModel.total"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="公共服务任务总支出："
          prop="expandOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseFourModel.expandOne"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="重点学科发展总支出："
          prop="expandTwo"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseFourModel.expandTwo"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="取消药品加成收入损失："
          prop="loss"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseFourModel.loss"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveHospitalBaseFour"
            >提交数据</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院基础数据的负债 -->
    <el-dialog
      title="负债"
      :visible.sync="liabilitiesdialogVisible"
      width="60%"
    >
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="180px"
        :model="saveHospitalBaseFiveModel"
        ref="saveHospitalBaseFiveRef"
        :rules="saveHospitalBaseFiveRules"
      >
        <el-form-item label="长期负债：" prop="liabilities" class="hosform">
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseFiveModel.liabilities"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="其中：基本建设负债："
          prop="baseLiabilities"
          class="hosform"
        >
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseFiveModel.baseLiabilities"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="设备购置负债："
          prop="payLiabilities"
          class="hosform"
        >
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseFiveModel.payLiabilities"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="xxxx："
          prop=""
          class="hosform"
          style="visibility: hidden"
        >
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveHospitalBaseFive"
            >提交数据</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院基础数据的医疗应收款 -->
    <el-dialog
      title="医疗应收款"
      :visible.sync="medreceivablesdialogVisible"
      width="60%"
    >
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="200px"
        :model="saveHospitalBaseSixModel"
        ref="saveHospitalBaseSixRef"
        :rules="saveHospitalBaseSixRules"
      >
        <el-form-item
          label="应收医保病人医药费："
          prop="medicalOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseSixModel.medicalOne"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="应收公费医疗病人医药费："
          prop="medicalTwo"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseSixModel.medicalTwo"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="确认无法收回的医疗欠费："
          prop="arrears"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseSixModel.arrears"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="其中：医保病人欠费："
          prop="arrearsOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveHospitalBaseSixModel.arrearsOne"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="公费医疗欠费：" prop="arrearsTwo" class="hosform">
          <div class="hosform">
            <el-input v-model="saveHospitalBaseSixModel.arrearsTwo"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="“三无”病人欠费："
          prop="arrearsThree"
          class="hosform"
        >
          <div class="hosform">
            <el-input
              v-model="saveHospitalBaseSixModel.arrearsThree"
            ></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveHospitalBaseSix"
            >提交数据</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院主要指标的效率、效益及装备 -->
    <el-dialog
      title="效率、效益及装备"
      :visible.sync="benefitdialogVisible"
      width="70%"
    >
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="300px"
        :model="saveIndexOneModel"
        ref="saveIndexOneRef"
        :rules="saveIndexOneRules"
      >
        <el-form-item
          label="每职工平均门急诊人次："
          prop="avgPeople"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.avgPeople"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="每职工平均住院床日："
          prop="avgBeds"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.avgBeds"></el-input>
            <div class="hosdanwei">(床日)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="每职工平均业务收入："
          prop="avgIncome"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.avgIncome"></el-input>
            <div class="hosdanwei">(元/人)</div>
          </div>
        </el-form-item>
        <el-form-item label="每床位占用固定资产：" prop="fixed" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.fixed"></el-input>
            <div class="hosdanwei">(元/张)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：专业设备：" prop="fixedOne" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.fixedOne"></el-input>
            <div class="hosdanwei">(元/张)</div>
          </div>
        </el-form-item>
        <el-form-item label="病床使用率：" prop="bedUse" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.bedUse"></el-input>
            <div class="hosdanwei">(人次)</div>
          </div>
        </el-form-item>
        <el-form-item label="病床周转次数：" prop="bedNum" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.bedNum"></el-input>
            <div class="hosdanwei">(次)</div>
          </div>
        </el-form-item>
        <el-form-item label="出院者平均住院天数：" prop="days" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.days"></el-input>
            <div class="hosdanwei">(天)</div>
          </div>
        </el-form-item>
        <el-form-item label="固定资产增长率：" prop="addRate" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.addRate"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item label="固定资产净值率：" prop="netRate" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.netRate"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item label="净资产增长率：" prop="growthRate" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.growthRate"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="百元固定资产医疗收入（不含药品收入）："
          prop="income"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexOneModel.income"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveIndexOne">提交数据</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院主要指标的负债及偿债能力 -->
    <el-dialog
      title="负债及偿债能力"
      :visible.sync="solvencydialogVisible"
      width="50%"
    >
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="120px"
        :model="saveIndexTwoModel"
        ref="saveIndexTwoRef"
        :rules="saveIndexTwoRules"
      >
        <el-form-item label="资产负债率：" prop="assetRatio" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexTwoModel.assetRatio"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item label="流动比率：" prop="currentRatio" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexTwoModel.currentRatio"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item label="速动比率：" prop="quickRatio" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexTwoModel.quickRatio"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="xxxx："
          prop=""
          class="hosform"
          style="visibility: hidden"
        >
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveIndexTwo">提交数据</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院主要指标的药品 -->
    <el-dialog title="药品" :visible.sync="drugsdialogVisible" width="60%">
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="200px"
        :model="saveIndexThreeModel"
        ref="saveIndexThreeRef"
        :rules="saveIndexThreeRules"
      >
        <el-form-item
          label="药品收入占业务收入比重："
          prop="weightOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexThreeModel.weightOne"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="药品收入占医疗收入比重："
          prop="weightTwo"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexThreeModel.weightTwo"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="药品加成收入："
          prop="medicalIncome"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexThreeModel.medicalIncome"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="药品加成率：" prop="medicalRatio" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexThreeModel.medicalRatio"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item label="药品周转天数：" prop="medicalDay" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexThreeModel.medicalDay"></el-input>
            <div class="hosdanwei">(天)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="xxxx："
          prop=""
          class="hosform"
          style="visibility: hidden"
        >
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveIndexThree">提交数据</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院主要指标的次均费用及财政补偿 -->
    <el-dialog
      title="次均费用及财政补偿"
      :visible.sync="compensationdialogVisible"
      width="60%"
    >
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="250px"
        :model="saveIndexFourModel"
        ref="saveIndexFourRef"
        :rules="saveIndexFourRules"
      >
        <el-form-item
          label="每门急诊人次平均收费水平："
          prop="chargeOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFourModel.chargeOne"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：药品费：" prop="chargeTwo" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexFourModel.chargeTwo"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="每床日平均收费水平："
          prop="chargeThree"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFourModel.chargeThree"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：药品费：" prop="chargeFour" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexFourModel.chargeFour"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="出院者平均医药费用："
          prop="chargeFive"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFourModel.chargeFive"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item label="其中：药品费：" prop="chargeSix" class="hosform">
          <div class="hosform">
            <el-input v-model="saveIndexFourModel.chargeSix"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="财政补助收入占总支出比例："
          prop="expandRatio"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFourModel.expandRatio"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="财政基本支出补助占基本支出比例："
          prop="baseRatio"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFourModel.baseRatio"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="在职职工人均财政基本支出补助："
          prop="avgRatio"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFourModel.avgRatio"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="xxxx："
          prop=""
          class="hosform"
          style="visibility: hidden"
        >
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveIndexFour">提交数据</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 医院主要指标的支出分析 -->
    <el-dialog
      title="支出分析"
      :visible.sync="analysisdialogVisible"
      width="70%"
    >
      <el-form
        style="width: 100%"
        :inline="true"
        label-width="320px"
        :model="saveIndexFiveModel"
        ref="saveIndexFiveRef"
        :rules="saveIndexFiveRules"
      >
        <el-form-item
          label="人员支出占业务支出比例："
          prop="expendOne"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFiveModel.expendOne"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="管理费用占业务支出比例："
          prop="expendTwo"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFiveModel.expendTwo"></el-input>
            <div class="hosdanwei">(%)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="在职职工人均工资性收入："
          prop="expendThree"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFiveModel.expendThree"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="百元医疗收入的医疗支出（不含药品收入）："
          prop="expendFour"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFiveModel.expendFour"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="百元医疗收入消耗卫生材料（不含药品收入）："
          prop="expendFive"
          class="hosform"
        >
          <div class="hosform">
            <el-input v-model="saveIndexFiveModel.expendFive"></el-input>
            <div class="hosdanwei">(元)</div>
          </div>
        </el-form-item>
        <el-form-item
          label="xxxx："
          prop=""
          class="hosform"
          style="visibility: hidden"
        >
          <div class="hosform">
            <el-input></el-input>
            <div class="hosdanwei">(x)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="saveIndexFive">提交数据</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    // 医院 薪资正则
    var SalarycheckAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("请设置医务人员信息！"));
      }
      setTimeout(() => {
        // console.log("number", Number.isInteger(value))
        if (!/^\d+$|^\d*\.\d+$/g.test(value)) {
          callback(new Error("请正确设置医务人员信息！"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      // 机构、人员、床位及工作量 添加
      saveHospitalBaseOneModel: {
        one: "", // 机构数
        organizationPeople: "", //编制人数
        jobPeople: "", // 在编在职人数
        workersPeople: "", // 在职职工人数
        accountant: "", // 其中：总会计师
        health: "", // 卫技人员
        doctor: "", // 其中：医师
        nurse: "", // 注册护士
        leave: "", // 离休人员
        retiree: "", // 退休人员
        temp: "", // 临时工人数
        tempHealth: "", // 其中：卫技人员
        authBeds: "", // 编制床位
        avgBeds: "", // 平均开放床位
        endBeds: "", // 期末实际开放床位
        patients: "", // 诊疗人次数
        patientsOne: "", // 其中：门急诊人次数
        dayOne: "", // 实际开放总床日数
        dayTwo: "", // 实际占用总床日数
        dayFour: "", // 出院者占用总床日
        discharge: "", //出院人数
      },
      saveHospitalBaseOneRules: {
        one: [
          {
            required: true,
            message: "请输入机构数",
            trigger: "blur",
          },
        ],
        organizationPeople: [
          {
            required: true,
            message: "请输入编制人数",
            trigger: "blur",
          },
        ],
        jobPeople: [
          {
            required: true,
            message: "请输入在编在职人数",
            trigger: "blur",
          },
        ],
        workersPeople: [
          {
            required: true,
            message: "请输入在职职工人数",
            trigger: "blur",
          },
        ],
        accountant: [
          {
            required: true,
            message: "请输入总会计师",
            trigger: "blur",
          },
        ],
        health: [
          {
            required: true,
            message: "请输入卫技人员",
            trigger: "blur",
          },
        ],
        doctor: [
          {
            required: true,
            message: "请输入医师",
            trigger: "blur",
          },
        ],
        nurse: [
          {
            required: true,
            message: "请输入注册护士",
            trigger: "blur",
          },
        ],
        leave: [
          {
            required: true,
            message: "请输入离休人员",
            trigger: "blur",
          },
        ],
        retiree: [
          {
            required: true,
            message: "请输入退休人员",
            trigger: "blur",
          },
        ],
        temp: [
          {
            required: true,
            message: "请输入临时工人数",
            trigger: "blur",
          },
        ],
        tempHealth: [
          {
            required: true,
            message: "请输入卫技人员",
            trigger: "blur",
          },
        ],
        authBeds: [
          {
            required: true,
            message: "请输入编制床位",
            trigger: "blur",
          },
        ],
        avgBeds: [
          {
            required: true,
            message: "请输入平均开放床位",
            trigger: "blur",
          },
        ],
        endBeds: [
          {
            required: true,
            message: "请输入期末实际开放床位",
            trigger: "blur",
          },
        ],
        patients: [
          {
            required: true,
            message: "请输入诊疗人次数",
            trigger: "blur",
          },
        ],
        patientsOne: [
          {
            required: true,
            message: "请输入门急诊人次数",
            trigger: "blur",
          },
        ],
        dayOne: [
          {
            required: true,
            message: "请输入实际开放总床日数",
            trigger: "blur",
          },
        ],
        dayTwo: [
          {
            required: true,
            message: "请输入实际占用总床日数",
            trigger: "blur",
          },
        ],
        dayFour: [
          {
            required: true,
            message: "请输入出院者占用总床日",
            trigger: "blur",
          },
        ],
        discharge: [
          {
            required: true,
            message: "请输入出院人数",
            trigger: "blur",
          },
        ],
      },
      // 固定资产 添加
      saveHospitalBaseTwoModel: {
        fixedOne: "", // 年初固定资产原值
        fixedTwo: "", // 本期增加固定资产原值
        fixedThree: "", // 本期减少固定资产原值
        endOne: "", // 期末固定资产原值
        endTwo: "", // 其中：专用设备
        endThree: "", // 房屋及建筑物
        endFour: "", // 其中：业务用房
        beginFixed: "", // 年初固定资产净值
        netOne: "", // 期末固定资产净值
        netTwo: "", // 其中：专用设备
        netThree: "", // 房屋及建筑物
        netFour: "", // 其中：业务用房
        expend: "", // 非财政资本性支出
      },
      saveHospitalBaseTwoRules: {
        fixedOne: [
          {
            required: true,
            message: "请输入年初固定资产原值",
            trigger: "blur",
          },
        ],
        fixedTwo: [
          {
            required: true,
            message: "请输入本期增加固定资产原值",
            trigger: "blur",
          },
        ],
        fixedThree: [
          {
            required: true,
            message: "请输入本期减少固定资产原值",
            trigger: "blur",
          },
        ],
        endOne: [
          {
            required: true,
            message: "请输入期末固定资产原值",
            trigger: "blur",
          },
        ],
        endTwo: [
          {
            required: true,
            message: "请输入专用设备",
            trigger: "blur",
          },
        ],
        endThree: [
          {
            required: true,
            message: "请输入房屋及建筑物",
            trigger: "blur",
          },
        ],
        endFour: [
          {
            required: true,
            message: "请输入业务用房",
            trigger: "blur",
          },
        ],
        beginFixed: [
          {
            required: true,
            message: "请输入年初固定资产净值",
            trigger: "blur",
          },
        ],
        netOne: [
          {
            required: true,
            message: "请输入期末固定资产净值",
            trigger: "blur",
          },
        ],
        netTwo: [
          {
            required: true,
            message: "请输入专用设备",
            trigger: "blur",
          },
        ],
        netThree: [
          {
            required: true,
            message: "请输入房屋及建筑物",
            trigger: "blur",
          },
        ],
        netFour: [
          {
            required: true,
            message: "请输入业务用房",
            trigger: "blur",
          },
        ],
        expend: [
          {
            required: true,
            message: "请输入非财政资本性支出",
            trigger: "blur",
          },
        ],
      },
      // 医院 收入 添加
      saveHospitalBaseThreeModel: {
        total: "", // 医疗收入总额
        totalOne: "", //其中:来源于城镇职工医疗保险基金
        totalTwo: "", // 来源于城镇居民医疗保险基金
        totalThree: "", // 来源于新型农村合作医疗基金
        totalFour: "", // 来源于城乡一体化医疗保险基金
        totalFive: "", // 来源于其他医疗保险基金
        finance: "", // 财政项目补助收入总额
        financeOne: "", // 其中：基本建设
        financeTwo: "", // 设备购置
        financeThree: "", // 公共服务任务
        financeFour: "", // 重点学科发展
        financeFive: "", // 取消药品加成专项补助
        financeSix: "", // 其他
      },
      saveHospitalBaseThreeRules: {
        total: [
          {
            required: true,
            message: "请输入医疗收入总额",
            trigger: "blur",
          },
        ],
        totalOne: [
          {
            required: true,
            message: "请输入来源于城镇职工医疗保险基金",
            trigger: "blur",
          },
        ],
        totalTwo: [
          {
            required: true,
            message: "请输入来源于城镇居民医疗保险基金",
            trigger: "blur",
          },
        ],
        totalThree: [
          {
            required: true,
            message: "请输入来源于新型农村合作医疗基金",
            trigger: "blur",
          },
        ],
        totalFour: [
          {
            required: true,
            message: "请输入来源于城乡一体化医疗保险基金",
            trigger: "blur",
          },
        ],
        totalFive: [
          {
            required: true,
            message: "请输入来源于其他医疗保险基金",
            trigger: "blur",
          },
        ],
        finance: [
          {
            required: true,
            message: "请输入财政项目补助收入总额",
            trigger: "blur",
          },
        ],
        financeOne: [
          {
            required: true,
            message: "请输入基本建设",
            trigger: "blur",
          },
        ],
        financeTwo: [
          {
            required: true,
            message: "请输入设备购置",
            trigger: "blur",
          },
        ],
        financeThree: [
          {
            required: true,
            message: "请输入公共服务任务",
            trigger: "blur",
          },
        ],
        financeFour: [
          {
            required: true,
            message: "请输入重点学科发展",
            trigger: "blur",
          },
        ],
        financeFive: [
          {
            required: true,
            message: "请输入取消药品加成专项补助",
            trigger: "blur",
          },
        ],
        financeSix: [
          {
            required: true,
            message: "请输入其他",
            trigger: "blur",
          },
        ],
      },
      // 医院 支出 添加
      saveHospitalBaseFourModel: {
        temp: "", // 临时工工资
        avg: "", // 药品平均占用额
        total: "", // 设备购置总额
        expandOne: "", // 公共服务任务总支出
        expandTwo: "", // 重点学科发展总支出
        loss: "", // 取消药品加成收入损失
      },
      saveHospitalBaseFourRules: {
        temp: [
          {
            required: true,
            message: "请输入临时工工资",
            trigger: "blur",
          },
        ],
        avg: [
          {
            required: true,
            message: "请输入药品平均占用额",
            trigger: "blur",
          },
        ],
        total: [
          {
            required: true,
            message: "请输入设备购置总额",
            trigger: "blur",
          },
        ],
        expandOne: [
          {
            required: true,
            message: "请输入公共服务任务总支出",
            trigger: "blur",
          },
        ],
        expandTwo: [
          {
            required: true,
            message: "请输入重点学科发展总支出",
            trigger: "blur",
          },
        ],
        loss: [
          {
            required: true,
            message: "请输入取消药品加成收入损失",
            trigger: "blur",
          },
        ],
      },
      // 医院 负债 添加
      saveHospitalBaseFiveModel: {
        liabilities: "", // 长期负债
        baseLiabilities: "", // 其中：基本建设负债
        payLiabilities: "", // 设备购置负债
      },
      saveHospitalBaseFiveRules: {
        liabilities: [
          {
            required: true,
            message: "请输入长期负债",
            trigger: "blur",
          },
        ],
        baseLiabilities: [
          {
            required: true,
            message: "请输入基本建设负债",
            trigger: "blur",
          },
        ],
        payLiabilities: [
          {
            required: true,
            message: "请输入设备购置负债",
            trigger: "blur",
          },
        ],
      },
      // 医院 医疗应收款 添加
      saveHospitalBaseSixModel: {
        medicalOne: "", // 应收医保病人医药费
        medicalTwo: "", // 应收公费医疗病人医药费
        arrears: "", // 确认无法收回的医疗欠费
        arrearsOne: "", // 医保病人欠费
        arrearsTwo: "", // 公费医疗欠费
        arrearsThree: "", // 三无”病人欠费
      },
      saveHospitalBaseSixRules: {
        medicalOne: [
          {
            required: true,
            message: "请输入应收医保病人医药费",
            trigger: "blur",
          },
        ],
        medicalTwo: [
          {
            required: true,
            message: "请输入应收公费医疗病人医药费",
            trigger: "blur",
          },
        ],
        arrears: [
          {
            required: true,
            message: "请输入确认无法收回的医疗欠费",
            trigger: "blur",
          },
        ],
        arrearsOne: [
          {
            required: true,
            message: "请输入医保病人欠费",
            trigger: "blur",
          },
        ],
        arrearsTwo: [
          {
            required: true,
            message: "请输入公费医疗欠费",
            trigger: "blur",
          },
        ],
        arrearsThree: [
          {
            required: true,
            message: "请输入三无'病人欠费'",
            trigger: "blur",
          },
        ],
      },
      // 医院 效率、效益及装备 添加
      saveIndexOneModel: {
        avgPeople: "", // 每职工平均门急诊人次
        avgBeds: "", // 每职工平均住院床日
        avgIncome: "", // 每职工平均业务收入
        fixed: "", // 每床位占用固定资产
        fixedOne: "", // 其中：专业设备
        bedUse: "", // 病房使用率
        bedNum: "", // 病房周转次数
        days: "", // 出院者平均住院天数
        addRate: "", // 固定资产增长率
        netRate: "", // 固定资产净值率
        growthRate: "", // 净资产增长率
        income: "", // 百元固定资产医疗收入(不含药品收入)
      },
      saveIndexOneRules: {
        avgPeople: [
          {
            required: true,
            message: "请输入每职工平均门急诊人次",
            trigger: "blur",
          },
        ],
        avgBeds: [
          {
            required: true,
            message: "请输入每职工平均住院床日",
            trigger: "blur",
          },
        ],
        avgIncome: [
          {
            required: true,
            message: "请输入每职工平均业务收入",
            trigger: "blur",
          },
        ],
        fixed: [
          {
            required: true,
            message: "请输入每床位占用固定资产",
            trigger: "blur",
          },
        ],
        fixedOne: [
          {
            required: true,
            message: "请输入专业设备",
            trigger: "blur",
          },
        ],
        bedUse: [
          {
            required: true,
            message: "请输入病房使用率",
            trigger: "blur",
          },
        ],
        bedNum: [
          {
            required: true,
            message: "请输入病房周转次数",
            trigger: "blur",
          },
        ],
        days: [
          {
            required: true,
            message: "请输入出院者平均住院天数",
            trigger: "blur",
          },
        ],
        addRate: [
          {
            required: true,
            message: "请输入固定资产增长率",
            trigger: "blur",
          },
        ],
        netRate: [
          {
            required: true,
            message: "请输入固定资产净值率",
            trigger: "blur",
          },
        ],
        growthRate: [
          {
            required: true,
            message: "请输入净资产增长率",
            trigger: "blur",
          },
        ],
        income: [
          {
            required: true,
            message: "请输入百元固定资产医疗收入",
            trigger: "blur",
          },
        ], //
      },
      // 医院 负债及偿债能力 添加
      saveIndexTwoModel: {
        assetRatio: "", // 资产负债率
        currentRatio: "", // 流动比率
        quickRatio: "", // 速冻比率
      },
      saveIndexTwoRules: {
        assetRatio: [
          {
            required: true,
            message: "请输入资产负债率",
            trigger: "blur",
          },
        ],
        currentRatio: [
          {
            required: true,
            message: "请输入流动比率",
            trigger: "blur",
          },
        ],
        quickRatio: [
          {
            required: true,
            message: "请输入速冻比率",
            trigger: "blur",
          },
        ],
      },
      // 医院 药品 添加
      saveIndexThreeModel: {
        weightOne: "", // 药品收入占业务收入比重
        weightTwo: "", // 药品收入占医疗收入比重
        medicalIncome: "", // 药品加成收入
        medicalRatio: "", // 药品加成率
        medicalDay: "", // 药品周转天数
      },
      saveIndexThreeRules: {
        weightOne: [
          {
            required: true,
            message: "请输入药品收入占业务收入比重",
            trigger: "blur",
          },
        ],
        weightTwo: [
          {
            required: true,
            message: "请输入药品收入占医疗收入比重",
            trigger: "blur",
          },
        ],
        medicalIncome: [
          {
            required: true,
            message: "请输入药品加成收入",
            trigger: "blur",
          },
        ],
        medicalRatio: [
          {
            required: true,
            message: "请输入药品加成率",
            trigger: "blur",
          },
        ],
        medicalDay: [
          {
            required: true,
            message: "请输入药品周转天数",
            trigger: "blur",
          },
        ],
      },
      // 医院 次均费用及财政补偿 添加
      saveIndexFourModel: {
        chargeOne: "", // 每门急诊人次平均收费水平
        chargeTwo: "", // 其中：药品费
        chargeThree: "", // 每床日平均收费水平
        chargeFour: "", // 其中：药品费
        chargeFive: "", // 出院者平均医药费用
        chargeSix: "", //其中:药品费
        expandRatio: "", // 财政补助收入占总支出比例
        baseRatio: "", // 财政基本支出补助占基本支出比例
        avgRatio: "", // 在职职工人均财政基本支出补助
      },
      saveIndexFourRules: {
        chargeOne: [
          {
            required: true,
            message: "请输入每门急诊人次平均收费水平",
            trigger: "blur",
          },
        ],
        chargeTwo: [
          {
            required: true,
            message: "请输入其中：药品费",
            trigger: "blur",
          },
        ],
        chargeThree: [
          {
            required: true,
            message: "请输入每床日平均收费水平",
            trigger: "blur",
          },
        ],
        chargeFour: [
          {
            required: true,
            message: "请输入其中：药品费",
            trigger: "blur",
          },
        ],
        chargeFive: [
          {
            required: true,
            message: "请输入出院者平均医药费用",
            trigger: "blur",
          },
        ],
        chargeSix: [
          {
            required: true,
            message: "请输入中",
            trigger: "blur",
          },
        ], //其:
        expandRatio: [
          {
            required: true,
            message: "请输入财政补助收入占总支出比例",
            trigger: "blur",
          },
        ],
        baseRatio: [
          {
            required: true,
            message: "请输入财政基本支出补助占基本支出比例",
            trigger: "blur",
          },
        ],
        avgRatio: [
          {
            required: true,
            message: "请输入在职职工人均财政基本支出补助",
            trigger: "blur",
          },
        ],
      },
      // 医院 支出分析 添加
      saveIndexFiveModel: {
        expendOne: "", // 人员支出占业务支出比例
        expendTwo: "", // 管理费用占业务支出比例
        expendThree: "", // 在职职工人员人均工资性收入
        expendFour: "", // 百元医疗收入的医疗支出（不含药品收入）
        expendFive: "", // 百元医疗收入的消耗卫生材料（不含药品收入）
      },
      saveIndexFiveRules: {
        expendOne: [
          {
            required: true,
            message: "请输入人员支出占业务支出比例",
            trigger: "blur",
          },
        ],
        expendTwo: [
          {
            required: true,
            message: "请输入管理费用占业务支出比例",
            trigger: "blur",
          },
        ],
        expendThree: [
          {
            required: true,
            message: "请输入在职职工人员人均工资性收入",
            trigger: "blur",
          },
        ],
        expendFour: [
          {
            required: true,
            message: "请输入百元医疗收入的医疗支出（不含药品收入）",
            trigger: "blur",
          },
        ],
        expendFive: [
          {
            required: true,
            message: "请输入百元医疗收入的消耗卫生材料（不含药品收入）",
            trigger: "blur",
          },
        ],
      },

      hospitalTitle: "",
      name: "",
      HospitaldatadialogVisible: false,
      // 医院薪资弹出框
      UpdatedialogVisible: false,
      // 医院 薪资
      UpdateDateModel: {
        doctorSalary: "", //医生薪资
        nursingSalary: "", //护士薪资
        technicianSalary: "", //技师薪资
        workSalary: "", //工薪薪资
      },
      UpdateDateRules: {
        doctorSalary: [
          { required: true, validator: SalarycheckAge, trigger: "blur" },
        ],
        nursingSalary: [
          { required: true, validator: SalarycheckAge, trigger: "blur" },
        ],
        technicianSalary: [
          { required: true, validator: SalarycheckAge, trigger: "blur" },
        ],
        workSalary: [
          { required: true, validator: SalarycheckAge, trigger: "blur" },
        ],
      },
      // 绩效分配结构
      Performance: {
        name: "",
      },
      // 绩效分配结构规则
      PerformanceRules: {
        name: [
          {
            required: true,
            message: "请输入绩效分配结构",
            trigger: "blur",
          },
        ],
      },
      // 添加年份
      AddDateModel: {
        // 年份名称
        year: "",
      },
      // 年份规则
      AddDateRules: {
        year: [
          {
            required: true,
            message: "请选择年份",
            trigger: "blur",
          },
        ],
      },
      // 点击点击添加年份弹出框
      AdddialogVisible: false,
      // 绩效分配结构弹出框
      EditdialogVisible: false,
      // 机构、人员、床位及工作量弹出框
      personneldialogVisible: false,
      type: "",
      // 固定资产
      fixassetsdialogVisible: false,
      // 收入
      incomedialogVisible: false,
      // 支出
      expendituredialogVisible: false,
      // 负债
      liabilitiesdialogVisible: false,
      // 医疗应收款
      medreceivablesdialogVisible: false,
      // 效率、效益及装备
      benefitdialogVisible: false,
      // 负债及偿债能力
      solvencydialogVisible: false,
      // 药品
      drugsdialogVisible: false,
      // 次均费用及财政补偿
      compensationdialogVisible: false,
      // 支出分析
      analysisdialogVisible: false,
      // 医院基础数据基期分页
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 表格数据
      tableData: [],
      // 医院表格
      HospitaltableData: [
        {
          name: "机构、人员、床位及工作量", //数据名称
        },
        {
          name: "固定资产", //数据名称
        },
        {
          name: "收入", //数据名称
        },
        {
          name: "支出", //数据名称
        },
        {
          name: "负债", //数据名称
        },
        {
          name: "医疗应收款", //数据名称
        },
      ],
      // 指标表格
      IndicatorstableData: [
        {
          name: "效率、效益及装备", //数据名称
        },
        {
          name: "负债及偿债能力", //数据名称
        },
        {
          name: "药品", //数据名称
        },
        {
          name: "次均费用及财政补偿", //数据名称
        },
        {
          name: "支出分析", //数据名称
        },
      ],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 总页数
      total: 1,
      //这条数据id
      id: "",
      // 基期年份
      Eyear: "",
      // 医院年份数据id
      HosId: "",
    };
  },
  created() {
    this.getHospitalBusinessList();
  },
  methods: {
    // 医院 机构、人员、床位及工作量 添加
    saveHospitalBaseOne() {
      this.$refs.saveHospitalBaseOneRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          one: this.saveHospitalBaseOneModel.one, // 机构数
          organizationPeople: this.saveHospitalBaseOneModel.organizationPeople, //编制人数
          jobPeople: this.saveHospitalBaseOneModel.jobPeople, // 在编在职人数
          workersPeople: this.saveHospitalBaseOneModel.workersPeople, // 在职职工人数
          accountant: this.saveHospitalBaseOneModel.accountant, // 其中：总会计师
          health: this.saveHospitalBaseOneModel.health, // 卫技人员
          doctor: this.saveHospitalBaseOneModel.doctor, // 其中：医师
          nurse: this.saveHospitalBaseOneModel.nurse, // 注册护士
          leave: this.saveHospitalBaseOneModel.leave, // 离休人员
          retiree: this.saveHospitalBaseOneModel.retiree, // 退休人员
          temp: this.saveHospitalBaseOneModel.temp, // 临时工人数
          tempHealth: this.saveHospitalBaseOneModel.tempHealth, // 其中：卫技人员
          authBeds: this.saveHospitalBaseOneModel.authBeds, // 编制床位
          avgBeds: this.saveHospitalBaseOneModel.avgBeds, // 平均开放床位
          endBeds: this.saveHospitalBaseOneModel.endBeds, // 期末实际开放床位
          patients: this.saveHospitalBaseOneModel.patients, // 诊疗人次数
          patientsOne: this.saveHospitalBaseOneModel.patientsOne, // 其中：门急诊人次数
          dayOne: this.saveHospitalBaseOneModel.dayOne, // 实际开放总床日数
          dayTwo: this.saveHospitalBaseOneModel.dayTwo, // 实际占用总床日数
          dayFour: this.saveHospitalBaseOneModel.dayFour, // 出院者占用总床日
          discharge: this.saveHospitalBaseOneModel.discharge, //出院人数
        });
        let res = await this.$axios.saveHospitalBaseOne(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加机构、人员、床位及工作量失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加机构、人员、床位及工作量成功",
            type: "success",
          });
          this.personneldialogVisible = false;
          this.$refs.saveHospitalBaseOneRef.resetFields();
        }
      });
    },
    // 医院 固定资产 添加
    saveHospitalBaseTwo() {
      this.$refs.saveHospitalBaseTwoRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          fixedOne: this.saveHospitalBaseTwoModel.fixedOne, // 年初固定资产原值
          fixedTwo: this.saveHospitalBaseTwoModel.fixedTwo, // 本期增加固定资产原值
          fixedThree: this.saveHospitalBaseTwoModel.fixedThree, // 本期减少固定资产原值
          endOne: this.saveHospitalBaseTwoModel.endOne, // 期末固定资产原值
          endTwo: this.saveHospitalBaseTwoModel.endTwo, // 其中：专用设备
          endThree: this.saveHospitalBaseTwoModel.endThree, // 房屋及建筑物
          endFour: this.saveHospitalBaseTwoModel.endFour, // 其中：业务用房
          beginFixed: this.saveHospitalBaseTwoModel.beginFixed, // 年初固定资产净值
          netOne: this.saveHospitalBaseTwoModel.netOne, // 期末固定资产净值
          netTwo: this.saveHospitalBaseTwoModel.netTwo, // 其中：专用设备
          netThree: this.saveHospitalBaseTwoModel.netThree, // 房屋及建筑物
          netFour: this.saveHospitalBaseTwoModel.netFour, // 其中：业务用房
          expend: this.saveHospitalBaseTwoModel.expend, // 非财政资本性支出
        });
        let res = await this.$axios.saveHospitalBaseTwo(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 固定资产失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 固定资产成功",
            type: "success",
          });
          this.fixassetsdialogVisible = false;
          this.$refs.saveHospitalBaseTwoRef.resetFields();
        }
      });
    },
    // 医院 收入 添加
    saveHospitalBaseThree() {
      this.$refs.saveHospitalBaseThreeRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          total: this.saveHospitalBaseThreeModel.total, // 医疗收入总额
          totalOne: this.saveHospitalBaseThreeModel.totalOne, //其中:来源于城镇职工医疗保险基金
          totalTwo: this.saveHospitalBaseThreeModel.totalTwo, // 来源于城镇居民医疗保险基金
          totalThree: this.saveHospitalBaseThreeModel.totalThree, // 来源于新型农村合作医疗基金
          totalFour: this.saveHospitalBaseThreeModel.totalFour, // 来源于城乡一体化医疗保险基金
          totalFive: this.saveHospitalBaseThreeModel.totalFive, // 来源于其他医疗保险基金
          finance: this.saveHospitalBaseThreeModel.finance, // 财政项目补助收入总额
          financeOne: this.saveHospitalBaseThreeModel.financeOne, // 其中：基本建设
          financeTwo: this.saveHospitalBaseThreeModel.financeTwo, // 设备购置
          financeThree: this.saveHospitalBaseThreeModel.financeThree, // 公共服务任务
          financeFour: this.saveHospitalBaseThreeModel.financeFour, // 重点学科发展
          financeFive: this.saveHospitalBaseThreeModel.financeFive, // 取消药品加成专项补助
          financeSix: this.saveHospitalBaseThreeModel.financeSix, // 其他
        });
        let res = await this.$axios.saveHospitalBaseThree(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 收入失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 收入成功",
            type: "success",
          });
          this.incomedialogVisible = false;
          this.$refs.saveHospitalBaseThreeRef.resetFields();
        }
      });
    },
    // 医院 支出 添加
    saveHospitalBaseFour() {
      this.$refs.saveHospitalBaseFourRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          temp: this.saveHospitalBaseFourModel.temp, // 临时工工资
          avg: this.saveHospitalBaseFourModel.avg, // 药品平均占用额
          total: this.saveHospitalBaseFourModel.total, // 设备购置总额
          expandOne: this.saveHospitalBaseFourModel.expandOne, // 公共服务任务总支出
          expandTwo: this.saveHospitalBaseFourModel.expandTwo, // 重点学科发展总支出
          loss: this.saveHospitalBaseFourModel.loss, // 取消药品加成收入损失
        });
        let res = await this.$axios.saveHospitalBaseFour(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 支出失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 支出成功",
            type: "success",
          });
          this.expendituredialogVisible = false;
          this.$refs.saveHospitalBaseFourRef.resetFields();
        }
      });
    },
    // 医院 负债 添加
    saveHospitalBaseFive() {
      this.$refs.saveHospitalBaseFiveRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          liabilities: this.saveHospitalBaseFiveModel.liabilities, // 长期负债
          baseLiabilities: this.saveHospitalBaseFiveModel.baseLiabilities, // 其中：基本建设负债
          payLiabilities: this.saveHospitalBaseFiveModel.payLiabilities, // 设备购置负债
        });
        let res = await this.$axios.saveHospitalBaseFive(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 负债失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 负债成功",
            type: "success",
          });
          this.liabilitiesdialogVisible = false;
          this.$refs.saveHospitalBaseFiveRef.resetFields();
        }
      });
    },
    // 医院 医疗应收款 添加
    saveHospitalBaseSix() {
      this.$refs.saveHospitalBaseSixRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          medicalOne: this.saveHospitalBaseSixModel.medicalOne, // 应收医保病人医药费
          medicalTwo: this.saveHospitalBaseSixModel.medicalTwo, // 应收公费医疗病人医药费
          arrears: this.saveHospitalBaseSixModel.arrears, // 确认无法收回的医疗欠费
          arrearsOne: this.saveHospitalBaseSixModel.arrearsOne, // 医保病人欠费
          arrearsTwo: this.saveHospitalBaseSixModel.arrearsTwo, // 公费医疗欠费
          arrearsThree: this.saveHospitalBaseSixModel.arrearsThree, // 三无”病人欠费
        });
        let res = await this.$axios.saveHospitalBaseSix(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 医疗应收款失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 医疗应收款成功",
            type: "success",
          });
          this.medreceivablesdialogVisible = false;
          this.$refs.saveHospitalBaseSixRef.resetFields();
        }
      });
    },
    // 医院 效率、效益及装备 添加
    saveIndexOne() {
      this.$refs.saveIndexOneRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          avgPeople: this.saveIndexOneModel.avgPeople, // 每职工平均门急诊人次
          avgBeds: this.saveIndexOneModel.avgBeds, // 每职工平均住院床日
          avgIncome: this.saveIndexOneModel.avgIncome, // 每职工平均业务收入
          fixed: this.saveIndexOneModel.fixed, // 每床位占用固定资产
          fixedOne: this.saveIndexOneModel.fixedOne, // 其中：专业设备
          bedUse: this.saveIndexOneModel.bedUse, // 病房使用率
          bedNum: this.saveIndexOneModel.bedNum, // 病房周转次数
          days: this.saveIndexOneModel.days, // 出院者平均住院天数
          addRate: this.saveIndexOneModel.addRate, // 固定资产增长率
          netRate: this.saveIndexOneModel.netRate, // 固定资产净值率
          growthRate: this.saveIndexOneModel.growthRate, // 净资产增长率
          income: this.saveIndexOneModel.income, // 百元固定资产医疗收入(不含药品收入)
        });
        let res = await this.$axios.saveIndexOne(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 效率、效益及装备失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 效率、效益及装备成功",
            type: "success",
          });
          this.benefitdialogVisible = false;
          this.$refs.saveIndexOneRef.resetFields();
        }
      });
    },
    // 医院 负债及偿债能力 添加
    saveIndexTwo() {
      this.$refs.saveIndexTwoRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          assetRatio: this.saveIndexTwoModel.assetRatio, // 资产负债率
          currentRatio: this.saveIndexTwoModel.currentRatio, // 流动比率
          quickRatio: this.saveIndexTwoModel.quickRatio, // 速冻比率
        });
        let res = await this.$axios.saveIndexTwo(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 负债及偿债能力失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 负债及偿债能力成功",
            type: "success",
          });
          this.solvencydialogVisible = false;
          this.$refs.saveIndexTwoRef.resetFields();
        }
      });
    },
    // 医院 药品 添加
    saveIndexThree() {
      this.$refs.saveIndexThreeRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          weightOne: this.saveIndexThreeModel.weightOne, // 药品收入占业务收入比重
          weightTwo: this.saveIndexThreeModel.weightTwo, // 药品收入占医疗收入比重
          medicalIncome: this.saveIndexThreeModel.medicalIncome, // 药品加成收入
          medicalRatio: this.saveIndexThreeModel.medicalRatio, // 药品加成率
          medicalDay: this.saveIndexThreeModel.medicalDay, // 药品周转天数
        });
        let res = await this.$axios.saveIndexThree(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 药品失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 药品成功",
            type: "success",
          });
          this.drugsdialogVisible = false;
          this.$refs.saveIndexThreeRef.resetFields();
        }
      });
    },
    // 医院 次均费用及财政补偿 添加
    saveIndexFour() {
      this.$refs.saveIndexFourRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          chargeOne: this.saveIndexFourModel.chargeOne, // 每门急诊人次平均收费水平
          chargeTwo: this.saveIndexFourModel.chargeTwo, // 其中：药品费
          chargeThree: this.saveIndexFourModel.chargeThree, // 每床日平均收费水平
          chargeFour: this.saveIndexFourModel.chargeFour, // 其中：药品费
          chargeFive: this.saveIndexFourModel.chargeFive, // 出院者平均医药费用
          chargeSix: this.saveIndexFourModel.chargeSix, //其中:药品费
          expandRatio: this.saveIndexFourModel.expandRatio, // 财政补助收入占总支出比例
          baseRatio: this.saveIndexFourModel.baseRatio, // 财政基本支出补助占基本支出比例
          avgRatio: this.saveIndexFourModel.avgRatio, // 在职职工人均财政基本支出补助
        });
        let res = await this.$axios.saveIndexFour(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 次均费用及财政补偿失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 次均费用及财政补偿成功",
            type: "success",
          });
          this.compensationdialogVisible = false;
          this.$refs.saveIndexFourRef.resetFields();
        }
      });
    },
    // 医院 支出分析 添加
    saveIndexFive() {
      this.$refs.saveIndexFiveRef.validate(async (valid) => {
        if (!valid) return;
        let data = _qs.stringify({
          businessId: this.HosId,
          expendOne: this.saveIndexFiveModel.expendOne, // 人员支出占业务支出比例
          expendTwo: this.saveIndexFiveModel.expendTwo, // 管理费用占业务支出比例
          expendThree: this.saveIndexFiveModel.expendThree, // 在职职工人员人均工资性收入
          expendFour: this.saveIndexFiveModel.expendFour, // 百元医疗收入的医疗支出（不含药品收入）
          expendFive: this.saveIndexFiveModel.expendFive, // 百元医疗收入的消耗卫生材料（不含药品收入）
        });
        let res = await this.$axios.saveIndexFive(data);
        // console.log(res);
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 500) {
          this.$message({
            message: "添加医院 支出分析失败",
            type: "error",
          });
        } else if (res.status == 200) {
          this.$message({
            message: "添加医院 支出分析成功",
            type: "success",
          });
          this.analysisdialogVisible = false;
          this.$refs.saveIndexFiveRef.resetFields();
        }
      });
    },
    // 点击2020年-医院主要指标的操作
    indicators(row) {
      this.name = row.name;
      if (row.name == "效率、效益及装备") {
        this.benefitdialogVisible = true;
        this.type = 7;
      } else if (row.name == "负债及偿债能力") {
        this.solvencydialogVisible = true;
        this.type = 8;
      } else if (row.name == "药品") {
        this.drugsdialogVisible = true;
        this.type = 9;
      } else if (row.name == "次均费用及财政补偿") {
        this.compensationdialogVisible = true;
        this.type = 10;
      } else if (row.name == "支出分析") {
        this.analysisdialogVisible = true;
        this.type = 11;
      }
      this.getBaseDetails();
    },
    // 点击操作医院基础数据
    Hospital(row) {
      this.name = row.name;
      if (row.name == "机构、人员、床位及工作量") {
        this.personneldialogVisible = true;
        this.type = 1;
      } else if (row.name == "固定资产") {
        this.fixassetsdialogVisible = true;
        this.type = 2;
      } else if (row.name == "收入") {
        this.incomedialogVisible = true;
        this.type = 3;
      } else if (row.name == "支出") {
        this.expendituredialogVisible = true;
        this.type = 4;
      } else if (row.name == "负债") {
        this.liabilitiesdialogVisible = true;
        this.type = 5;
      } else if (row.name == "医疗应收款") {
        this.medreceivablesdialogVisible = true;
        this.type = 6;
      }
      this.getBaseDetails();
    },
    // 回显数据
    async getBaseDetails() {
      let data = {
        businessId: this.HosId,
        type: this.type,
      };
      let res = await this.$axios.getBaseDetails(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 500) {
        this.$message({
          message: "数据回显失败",
          type: "error",
        });
      } else if (res.data.code == 200) {
        if (res.data.data) {
          if (this.name == "机构、人员、床位及工作量") {
            this.saveHospitalBaseOneModel = res.data.data;
          } else if (this.name == "固定资产") {
            this.saveHospitalBaseTwoModel = res.data.data;
          } else if (this.name == "收入") {
            this.saveHospitalBaseThreeModel = res.data.data;
          } else if (this.name == "支出") {
            this.saveHospitalBaseFourModel = res.data.data;
          } else if (this.name == "负债") {
            this.saveHospitalBaseFiveModel = res.data.data;
          } else if (this.name == "医疗应收款") {
            this.saveHospitalBaseSixModel = res.data.data;
          } else if (this.name == "效率、效益及装备") {
            this.saveIndexOneModel = res.data.data;
          } else if (this.name == "负债及偿债能力") {
            this.saveIndexTwoModel = res.data.data;
          } else if (this.name == "药品") {
            this.saveIndexThreeModel = res.data.data;
          } else if (this.name == "次均费用及财政补偿") {
            this.saveIndexFourModel = res.data.data;
          } else if (this.name == "支出分析") {
            this.saveIndexFiveModel = res.data.data;
          }
        }
      }
    },
    // 选择年份的改变
    changeOptios(val) {
      if (val) {
        this.Eyear = val;
      }
    },
    // 修改医院 薪资保存
    addUpdateDate() {
      this.$refs.UpdateDateRef.validate(async (valid) => {
        if (valid) {
          let data = _qs.stringify({
            id: this.id,
            doctorSalary: this.UpdateDateModel.doctorSalary,
            nursingSalary: this.UpdateDateModel.nursingSalary,
            technicianSalary: this.UpdateDateModel.technicianSalary,
            workSalary: this.UpdateDateModel.workSalary,
          });
          let { data: res } = await this.$axios.updateHospitalSalary(data);
          // console.log(res);
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 500) {
            this.$message({
              message: "修改医院薪资失败",
              type: "error",
            });
          } else if (res.code == 200) {
            this.$message({
              message: "修改医院薪资成功",
              type: "success",
            });
            this.UpdatedialogVisible = false;
            this.getHospitalBusinessList();
          }
        }
      });
    },
    // 点击操作绩效分配结构
    editJixiao(row) {
      console.log(row);
      this.EditdialogVisible = true;
    },
    // // 修改医院 薪资弹出框取消正则
    UpdatedialogVisibleClose() {
      this.UpdatedialogVisible = false;
      this.$refs.UpdateDateRef.resetFields();
    },
    // 添加年份的时候取消正则
    AdddialogVisibleClose() {
      this.AdddialogVisible = false;
      this.$refs.AddDateRef.resetFields();
    },
    // 医院年份列表 分页
    async getHospitalBusinessList() {
      let data = {
        hospitalId: this.hospitalId, //医院id
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示条数
        orderByColumn: "year", //排序参数 year 年份
        isAsc: "desc", //顺序 desc降序 asc升序
      };
      let { data: res } = await this.$axios.getHospitalBusinessList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.rows;
        this.total = res.total;
      }
    },
    // 医院基础数据基期分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.getHospitalBusinessList();
    },
    // 点击添加年份
    depaddYear() {
      this.AdddialogVisible = true;
    },
    // 点击添加年份额添加
    addYear() {
      this.$refs.AddDateRef.validate(async (valid) => {
        if (valid) {
          let data = _qs.stringify({
            hospitalId: this.hospitalId, //医院id
            year: this.Eyear, //年份 例：2021
          });

          let { data: res } = await this.$axios.addHospitalBusiness(data);
          // console.log(res);
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 500) {
            this.$message({
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 200) {
            this.$message({
              message: "添加医院年份信息成功",
              type: "success",
            });
            this.AdddialogVisible = false;
            this.getHospitalBusinessList();
            this.$refs.AddDateRef.resetFields();
          }
        }
      });
    },
    // 点击操作删除
    listJixiao(row) {
      this.hospitalTitle = row.year;
      this.HospitaldatadialogVisible = true;
      this.HosId = row.id;
      this.hospitalList();
    },
    // 医院数据更新时间列表
    async hospitalList() {
      let data = {
        businessId: this.HosId,
      };
      let { data: res } = await this.$axios.hospitalList(data);
      // console.log(res)
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: "获取医院数据更新时间列表失败",
          type: "error",
        });
      } else if (res.code == 200) {
        this.HospitaltableData.updateDate = res.data.slice(0, 6).updateDate;
        this.HospitaltableData.status = res.data.slice(0, 6).status;
        this.IndicatorstableData.updateDate = res.data.slice(-5).updateDate;
        this.IndicatorstableData.status = res.data.slice(-5).status;
      }
    },
    HospitaldatadialogVisibleClose() {
      this.HospitaldatadialogVisible = false;
    },
    // 点击操作的第三个按钮修改医院 薪资
    async updateHospitalSalary(row) {
      this.id = row.id;
      this.UpdatedialogVisible = true;
      let data = { id: row.id };
      let { data: res } = await this.$axios.getHospitalBusiness(data);
      // console.log(res.data);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.UpdateDateModel = res.data;
      }
    },
    // 点击绩效分配结构提交数据
    editdialogKeshi() {
      this.$refs.PerformanceRef.validate(async (valid) => {
        if (!valid) return;
        this.EditdialogVisible = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .updateTan .el-dialog__body {
  padding: 0;
}

.addprimary {
  margin-top: 10px;
}

.hosform {
  display: flex;
  .hosdanwei {
    margin-left: 10px;
    margin-right: 10px;
    width: 53px;
  }
}
.hosrowTable {
  width: 95%;
  margin: 0 auto;
  .hostextw {
    float: left;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.hosrow {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
</style>
